function forceDownloadFile(url: string): void {
  const downloadLink = document.createElement("a")
  downloadLink.setAttribute("download", "")
  downloadLink.href = url
  document.body.appendChild(downloadLink)
  downloadLink.click()
  downloadLink.remove()
}

export { forceDownloadFile }
